import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'

import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)


export const actions: ActionTree<RootState, RootState> = {

  prepare({ commit }, params) {
    if (params) {
      commit('SET_IDENTITY', params)
    }
  },

  authorize({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`/admins/rest/v1/tokens`, params)
        .then((loginResponse: any) => {
          // tslint:disable-next-line:no-console
          console.log('loginResponse', loginResponse.data)

          if (loginResponse.data.token) {
            commit('SET_IDENTITY', {
              token: loginResponse.data.token.value,
              actor: loginResponse.data.token.admin,
            })

            resolve()
          }
          else {
            reject(loginResponse.data)
          }
        })
        .catch((error: any) => {
          if (error.response.status === 422) {
            reject(error.response.data.error)
          }
        })

    })
  },

  logout({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      commit('SET_IDENTITY', {
        actor: null,
        token: null,
      })

      resolve()
    })
  },
}

export const mutations: MutationTree<RootState> = {

  SET_IDENTITY(state: any, payload) {
    state.actor = payload.actor
    state.token = payload.token
  },

  /* Aside Mobile */
  asideMobileStateToggle(state, payload = null) {
    const htmlClassName = 'has-aside-mobile-expanded'

    let isShow

    if (payload !== null) {
      isShow = payload
    }
    else {
      isShow = !state.isAsideMobileExpanded
    }

    if (isShow) {
      document.documentElement.classList.add(htmlClassName)
    }
    else {
      document.documentElement.classList.remove(htmlClassName)
    }

    state.isAsideMobileExpanded = isShow
  },

  /* Aside Mobile */
  asideStateToggle(state, payload = null) {
    const htmlClassName = 'has-aside-expanded'

    let isShow

    if (payload !== null) {
      isShow = payload
    }
    else {
      isShow = !state.isAsideExpanded
    }

    if (isShow) {
      document.documentElement.classList.add(htmlClassName)
    }
    else {
      document.documentElement.classList.remove(htmlClassName)
    }

    state.isAsideExpanded = isShow
  },

  openSubmenu(state, payload = null) {
    state.activeSubmenu = payload
  },

  hideSubmenu(state, payload = null) {
    state.activeSubmenu = null
  },

}


export const getters: GetterTree<RootState, RootState> = {
  isAuthenticated: (state: any) => {
    return !!state.token
  },
}

export default new Vuex.Store({
  state: {
    version: '0.1',

    actor: null,
    token: null,

    recivedPushMessages: [],
    performedPushMessages: [],
    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideExpanded: true,
    isAsideMobileExpanded: false,

    activeSubmenu: null,
  },
  mutations,
  actions,
  getters,
})
