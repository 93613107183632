<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon"/>
      </a>
      <a class="navbar-item is-hidden-mobile" @click.prevent="menuToggle">
        <b-icon :icon="menuToggleIcon"/>
      </a>
    </div>

    <div class="navbar-brand is-right">
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default"/>
      </a>
    </div>

    <div class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
      <div class="navbar-end">
        <div class="navbar-item has-dropdown has-dropdown-with-icons">
          <a class="navbar-link is-arrowless">
            <user-avatar/>

            <div class="is-user-name">
              <span v-if="actor">{{ actor.name }}</span>
            </div>
          </a>
        </div>

        <a class="navbar-item is-desktop-icon-only" title="Выйти" @click="logout">
          <b-icon icon="sign-out-alt" custom-size="default"/>
          <span>Выйти</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
// import NavBarMenu from '@/components/Layout/NavBarMenu'
import UserAvatar from '@/components/Layout/UserAvatar'

export default {
  name: 'NavBar',
  components: {
    UserAvatar,
    // NavBarMenu
  },
  data() {
    return {
      isMenuNavBarActive: false,
    }
  },
  computed: {
    menuNavBarToggleIcon() {
      return (this.isMenuNavBarActive) ? 'close' : 'ellipsis-v'
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? 'caret-left' : 'caret-right'
    },
    menuToggleIcon() {
      return this.isAsideExpanded ? 'caret-left' : 'caret-right'
    },
    ...mapState([
      'isNavBarVisible',
      'isAsideMobileExpanded',
      'isAsideExpanded',
      'userName',
    ]),
    ...mapState([
      'actor',
    ]),
  },
  methods: {
    ...mapActions([
      'logout',
    ]),
    menuToggleMobile() {
      this.$store.commit('asideMobileStateToggle')
    },
    menuToggle() {
      this.$store.commit('asideStateToggle')
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
  },
}
</script>
