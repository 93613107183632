import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import NotFound from '../components/Layout/NotFound.vue'
import UnderDevelopment from '../components/Layout/UnderDevelopment.vue'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    meta: {
      title: 'Главная',
    },
    path: '/',
    name: 'root',
    component: UnderDevelopment,
    redirect: '/brand'
  },

  {
    meta: {
      title: 'Бренды',
    },
    path: '/brand',
    name: 'brand-list',
    component: () => import(
      /* webpackChunkName: "BrandList" */
      '../modules/BrandList/BrandList.vue'
      ),
  },

  {
    meta: {
      title: 'Бренды',
    },
    path: '/brand/new',
    name: 'brand-new',
    component: () => import(
      /* webpackChunkName: "BrandForm" */
      '../modules/BrandTabs/BrandForm/BrandForm.vue'
      ),
  },

  {
    meta: {
      title: 'Бренды',
    },
    path: '/brand/tabs',
    redirect: '/brand/details/:id/edit',
    name: 'brand-tabs',
    component: () => import(
      '../modules/BrandTabs/BrantDetails.vue'
      ),
    children: [
      {
        path: '/brand/details/:id/edit',
        name: 'brand-edit',
        component: () => import(
          /* webpackChunkName: "BrandForm" */
          '../modules/BrandTabs/BrandForm/BrandForm.vue'
          ),
      },
      {
        path: '/brands/feedbacks/:id',
        name: 'brand-comment',
        component: () => import(
          /* webpackChunkName: "BrandComments" */
          '../modules/BrandTabs/BrandFeedback/BrandFeedback.vue'
          ),
      },
    ],
  },

  {
    meta: {
      title: 'Просмотр бренда',
    },
    path: '/brand/details/:id/observe',
    name: 'brand-observe',
    component: () => import(
      /* webpackChunkName: "BrandForm" */
      '../modules/BrandTabs/BrandObserve/BrandObserve.vue'
      ),
  },

  // {
  //   meta: {
  //     title: 'Бренды',
  //   },
  //   path: '/brand/details/:id/edit',
  //   name: 'brand-edit',
  //   component: () => import(
  //     /* webpackChunkName: "BrandForm" */
  //     '../modules/BrandForm/BrandForm.vue'
  //   ),
  // },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Организации',
    },
    path: '/merchants',
    name: 'merchant-list',
    component: () => import(
      /* webpackChunkName: "MerchantList" */
      '../modules/MerchantList/MerchantList.vue'
      ),
  },

  {
    meta: {
      title: 'Организации',
    },
    path: '/merchants/new',
    name: 'merchant-new',
    component: () => import(
      /* webpackChunkName: "MerchantForm" */
      '../modules/MerchantForm/MerchantForm.vue'
      ),
  },

  {
    meta: {
      title: 'Организации',
    },
    path: '/merchants/details/:id/edit',
    name: 'merchant-edit',
    component: () => import(
      /* webpackChunkName: "MerchantForm" */
      '../modules/MerchantForm/MerchantForm.vue'
      ),
  },

  {
    meta: {
      title: 'Организации',
    },
    path: '/merchants/details/:id/edit-credentials',
    name: 'merchant-edit-credentials',
    component: () => import(
      /* webpackChunkName: "MerchantCredentialsForm" */
      '../modules/MerchantCredentialsForm/MerchantCredentialsForm.vue'
      ),
  },

  {
    meta: {
      title: 'Организации',
    },
    path: '/merchants/details/:id/edit-ecp',
    name: 'merchant-edit-ecp',
    component: () => import(
      /* webpackChunkName: "MerchantEcpForm" */
      '../modules/MerchantEcpForm/MerchantEcpForm.vue'
      ),
  },

  {
    meta: {
      title: 'Организации',
    },
    path: '/merchants/details/:id/observe',
    name: 'merchant-observe',
    component: () => import(
      /* webpackChunkName: "MerchantObserve" */
      '../modules/MerchantObserve/MerchantObserve.vue'
      ),
  },

  {
    meta: {
      title: 'Регистрация организации',
    },
    path: '/merchants/register',
    name: 'merchant-register',
    component: () => import(
      /* webpackChunkName: "MerchantList" */
      '../modules/MerchantRegister/MerchantRegister.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Точки продаж',
    },
    path: '/outlets',
    name: 'outlet-list',
    component: () => import(
      /* webpackChunkName: "OutletList" */
      '../modules/OutletList/OutletList.vue'
      ),
  },

  {
    meta: {
      title: 'Точки продаж',
    },
    path: '/outlets/new',
    name: 'outlet-new',
    component: () => import(
      /* webpackChunkName: "OutletForm" */
      '../modules/OutletForm/OutletForm.vue'
      ),
  },

  {
    meta: {
      title: 'Точки продаж',
    },
    path: '/outlets/details/:id/edit',
    name: 'outlet-edit',
    component: () => import(
      /* webpackChunkName: "OutletForm" */
      '../modules/OutletForm/OutletForm.vue'
      ),
  },

  {
    meta: {
      title: 'Точка продаж',
    },
    path: '/outlets/details/:id/observe',
    name: 'outlet-observe',
    component: () => import(
      /* webpackChunkName: "OutletObserve" */
      '../modules/OutletObserve/OutletObserve.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Покупатели',
    },
    path: '/customers',
    name: 'customer-list',
    component: () => import(
      /* webpackChunkName: "CustomerList" */
      '../modules/CustomerList/CustomerList.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Покупатели',
    },
    path: '/suppliers',
    name: 'suppliers-list',
    component: () => import(
      /* webpackChunkName: "SupplierList" */
      '../modules/SupplierList/SupplierList.vue'
      ),
  },
  {
    meta: {
      title: 'Покупатели',
    },
    path: '/suppliers/new',
    name: 'suppliers-new',
    component: () => import(
      /* webpackChunkName: "SupplierForm" */
      '../modules/SupplierList/SupplierForm/SupplierForm.vue'
      ),
  },
  {
    meta: {
      title: 'Покупатели',
    },
    path: '/suppliers/:id',
    name: 'suppliers-edit',
    component: () => import(
      /* webpackChunkName: "SupplierForm" */
      '../modules/SupplierList/SupplierForm/SupplierForm.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Товарная номенклатура',
    },
    path: '/nomenclatures',
    name: 'nomenclatures-list',
    component: () => import(
      /* webpackChunkName: "Nomenclature" */
      '../modules/Nomenclature/Nomenclature.vue'
      ),
  },
  {
    meta: {
      title: 'Товарная номенклатура',
    },
    path: '/nomenclatures/new',
    name: 'nomenclatures-new',
    component: () => import(
      /* webpackChunkName: "NomenclatureForm" */
      '../modules/Nomenclature/NomenclatureForm/NomenclatureForm.vue'
      ),
  },
  {
    meta: {
      title: 'Товарная номенклатура',
    },
    path: '/nomenclatures/:id',
    name: 'nomenclatures-edit',
    component: () => import(
      /* webpackChunkName: "NomenclatureForm" */
      '../modules/Nomenclature/NomenclatureForm/NomenclatureForm.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Роли',
    },
    path: '/admin-roles',
    name: 'admin-role-list',
    component: () => import(
      /* webpackChunkName: "AdminRoleList" */
      '../modules/AdminRoleList/AdminRoleList.vue'
      ),
  },

  {
    meta: {
      title: 'Администраторы',
    },
    path: '/admins',
    name: 'admin-list',
    component: () => import(
      /* webpackChunkName: "AdminList" */
      '../modules/AdminList/AdminList.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Города',
    },
    path: '/cities',
    name: 'city-list',
    component: () => import(
      /* webpackChunkName: "CityList" */
      '../modules/CityList/CityList.vue'
      ),
  },

  {
    meta: {
      title: 'Города',
    },
    path: '/cities/new',
    name: 'city-new',
    component: () => import(
      /* webpackChunkName: "CityForm" */
      '../modules/CityForm/CityForm.vue'
      ),
  },

  {
    meta: {
      title: 'Города',
    },
    path: '/cities/details/:id/edit',
    name: 'city-edit',
    component: () => import(
      /* webpackChunkName: "CityForm" */
      '../modules/CityForm/CityForm.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Категории организаций',
    },
    path: '/merchant-categories',
    name: 'merchant-category-list',
    component: () => import(
      /* webpackChunkName: "MerchantCategoryList" */
      '../modules/MerchantCategoryList/MerchantCategoryList.vue'
      ),
  },

  {
    meta: {
      title: 'Категории организаций',
    },
    path: '/merchant-categories/new',
    name: 'merchant-category-new',
    component: () => import(
      /* webpackChunkName: "MerchantCategoryForm" */
      '../modules/MerchantCategoryForm/MerchantCategoryForm.vue'
      ),
  },

  {
    meta: {
      title: 'Категории организаций',
    },
    path: '/merchant-categories/details/:id/edit',
    name: 'merchant-category-edit',
    component: () => import(
      /* webpackChunkName: "MerchantCategoryForm" */
      '../modules/MerchantCategoryForm/MerchantCategoryForm.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Категории продуктов',
    },
    path: '/product-categories',
    name: 'product-category-list',
    component: () => import(
      /* webpackChunkName: "ProductCategoriyList" */
      '../modules/ProductCategoriyList/ProductCategoriyList.vue'
      ),
  },

  {
    meta: {
      title: 'Категории продуктов',
    },
    path: '/product-categories/new',
    name: 'product-category-new',
    component: () => import(
      /* webpackChunkName: "ProductCategoriyForm" */
      '../modules/ProductCategoriyForm/ProductCategoriyForm.vue'
      ),
  },

  {
    meta: {
      title: 'Категории продуктов',
    },
    path: '/product-categories/details/:id/edit',
    name: 'product-category-edit',
    component: () => import(
      /* webpackChunkName: "ProductCategoriyForm" */
      '../modules/ProductCategoriyForm/ProductCategoriyForm.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Шаблоны продуктов',
    },
    path: '/product-patterns',
    name: 'product-pattern-list',
    component: () => import(
      /* webpackChunkName: "ProductPatternList" */
      '../modules/ProductPatternList/ProductPatternList.vue'
      ),
  },

  {
    meta: {
      title: 'Категории продуктов',
    },
    path: '/product-patterns/new',
    name: 'product-pattern-new',
    component: () => import(
      /* webpackChunkName: "ProductPatternForm" */
      '../modules/ProductPatternForm/ProductPatternForm.vue'
      ),
  },

  {
    meta: {
      title: 'Категории продуктов',
    },
    path: '/product-patterns/details/:id/edit',
    name: 'product-pattern-edit',
    component: () => import(
      /* webpackChunkName: "ProductPatternForm" */
      '../modules/ProductPatternForm/ProductPatternForm.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Серии карт',
    },
    path: '/card-series',
    name: 'card-series-list',
    component: () => import(
      /* webpackChunkName: "CardSeriesList" */
      '../modules/CardSeriesList/CardSeriesList.vue'
      ),
  },

  {
    meta: {
      title: 'Создание новой серии',
    },
    path: '/card-series/new',
    name: 'card-series-new',
    component: () => import(
      /* webpackChunkName: "CardSeriesForm" */
      '../modules/CardSeriesForm/CardSeriesForm.vue'
      ),
  },

  {
    meta: {
      title: 'Редактирование серии',
    },
    path: '/card-series/details/:id/edit',
    name: 'card-series-edit',
    component: () => import(
      /* webpackChunkName: "CardSeriesForm" */
      '../modules/CardSeriesForm/CardSeriesForm.vue'
      ),
  },
  // -----------------------------------------------------------------------------------------------------
  {
    meta: {
      title: 'Бонусы',
    },
    path: '/bonus-list',
    name: 'bonus-list',
    component: () => import(
      /* webpackChunkName: "BonusList" */
      '../modules/BonusList/BonusList.vue'
      ),
  },
  {
    meta: {
      title: 'Акции',
    },
    path: '/promotion-list',
    name: 'promotion-list',
    component: () => import(
      /* webpackChunkName: "PromotionList" */
      '../modules/PromotionList/PromotionList.vue'
      ),
  },
  {
    meta: {
      title: 'Акции',
    },
    path: '/promotion/new',
    name: 'promotion-new',
    component: () => import(
      /* webpackChunkName: "PromotionNew" */
      '../modules/PromotionList/PromotionNew/PromotionNew.vue'
      ),
  },
  {
    meta: {
      title: 'Акции',
    },
    path: '/promotion/details/:id/edit',
    name: 'promotion-edit',
    component: () => import(
      /* webpackChunkName: "OnboardingForm" */
      '../modules/PromotionList/PromotionNew/PromotionNew.vue'
      ),
  },
  {
    meta: {
      title: 'Кэшбэк',
    },
    path: '/cashback',
    name: 'cashback-list',
    component: () => import(
      /* webpackChunkName: "СashbackList" */
      '../modules/Cashback/СashbackList.vue'
      ),
  },
  {
    meta: {
      title: 'Кэшбэк',
    },
    path: '/cashback/new',
    name: 'cashback-new',
    component: () => import(
      /* webpackChunkName: "СashbackForm" */
      '../modules/Cashback/СashbackForm/СashbackForm.vue'
      ),
  },
  {
    meta: {
      title: 'Кэшбэк',
    },
    path: '/cashback/details/:id/edit',
    name: 'cashback-edit',
    component: () => import(
      /* webpackChunkName: "СashbackForm" */
      '../modules/Cashback/СashbackForm/СashbackForm.vue'
      ),
  },
  {
    meta: {
      title: 'Кэшбэк Транзацкии',
    },
    path: '/cashback/transactions/',
    name: 'cashback-transactions',
    component: () => import(
      /* webpackChunkName: "CashbackTransactions" */
      '../modules/CashbackTransactions/CashbackTransactions.vue'
      ),
  },
  {
    meta: {
      title: 'Создание нового бонуса',
    },
    path: '/bonus/new',
    name: 'bonus-new',
    component: () => import(
      /* webpackChunkName: "BonusForm" */
      '../modules/BonusForm/BonusForm.vue'
      ),
  },

  {
    meta: {
      title: 'Редактирование бонуса',
    },
    path: '/bonus/details/:id/edit',
    name: 'bonus-edit',
    component: () => import(
      /* webpackChunkName: "BonusForm" */
      '../modules/BonusForm/BonusForm.vue'
      ),
  },
  {
    meta: {
      title: 'Обратная связь',
    },
    path: '/problem-list',
    name: 'problem-list',
    component: () => import(
      /* webpackChunkName: "ProblemList" */
      '../modules/ProblemList/ProblemList.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Тур',
    },
    path: '/onboardings',
    name: 'onboarding-list',
    component: () => import(
      /* webpackChunkName: "OnboardingList" */
      '../modules/OnboardingList/OnboardingList.vue'
      ),
  },

  {
    meta: {
      title: 'Тур',
    },
    path: '/onboardings/new',
    name: 'onboarding-new',
    component: () => import(
      /* webpackChunkName: "OnboardingForm" */
      '../modules/OnboardingForm/OnboardingForm.vue'
      ),
  },

  {
    meta: {
      title: 'Тур',
    },
    path: '/onboardings/details/:id/edit',
    name: 'onboarding-edit',
    component: () => import(
      /* webpackChunkName: "OnboardingForm" */
      '../modules/OnboardingForm/OnboardingForm.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Политика конфиденциальности',
    },
    path: '/texts/privacy-policy/edit',
    name: 'privacy-policy-edit',
    component: () => import(
      /* webpackChunkName: "PrivacyPolicyForm" */
      '../modules/TextEditors/PrivacyPolicyForm/PrivacyPolicyForm.vue'
      ),
  },

  // ------------------------------------------------------------------------------------------------------
  {
    meta: {
      title: 'Отчет по каналам поступления чеков',
    },
    path: '/reports/by-channel',
    name: 'report-by-channel',
    component: () => import(
      '../modules/ReportByChannel/ReportByChannel.vue'
      ),
  },
  {
    meta: {
      title: 'Статистика по начисленным бонусам',
    },
    path: '/reports/bonus-stats',
    name: 'report-bonus-stats',
    component: () => import(
      '../modules/ReportBonusStats/ReportBonusStats.vue'
      ),
  },
  {
    meta: {
      title: 'Отчет по активированным картам',
    },
    path: '/reports/by-cards',
    name: 'report-by-cards',
    component: () => import(
      '../modules/ReportByCards/ReportByCards.vue'
      ),
  },
  {
    meta: {
      title: 'Чеки',
    },
    path: '/reports/receipts',
    name: 'report-receipts',
    component: () => import(
      '../modules/ReportReceipts/ReportReceipts.vue'
      ),
  },
  {
    meta: {
      title: 'Чеки',
    },
    path: '/reports/customers',
    name: 'report-customer',
    component: () => import(
      '../modules/ReportByCustomer/ReportByCustomer.vue'
      ),
  },
  // ------------------------------------------------------------------------------------------------------

  {
    meta: {
      title: 'Уведомления',
    },
    path: '/notifications',
    name: 'notifications',
    component: () => import(
      '../modules/Notifications/Notifications.vue'
      ),
  },
  {
    meta: {
      title: 'Уведомления',
    },
    path: '/notifications/new',
    name: 'notifications-new',
    component: () => import(
      '../modules/NotificationForm/NotificationForm.vue'
      ),
  },
  {
    meta: {
      title: 'Уведомления',
    },
    path: '/notifications/:id/edit',
    name: 'notifications-edit',
    component: () => import(
      '../modules/NotificationForm/NotificationForm.vue'
      ),
  },
  {
    meta: {
      title: 'Сообщения',
    },
    path: '/messages',
    name: 'messages',
    component: () => import(
      '../modules/Messages/Messages.vue'
      ),
  },
  // ------------------------------------------------------------------------------------------------------
  /*{
    meta: {
      title: 'Настройки',
    },
    path: '/settings',
    name: 'settings',
    component: () => import(
      '../modules/SettingsForm/SettingsForm.vue'
    ),
  },*/

  {
    meta: {
      title: 'Настройки',
    },
    path: '/settings',
    name: 'settings',
    component: () => import(
      '../modules/SettingsTabs/SettingsTabs.vue'
      ),
  },

  {
    meta: {
      title: 'Модерация',
    },
    path: '/moderation',
    name: 'moderation',
    component: () => import(
      '../modules/Moderation/Moderation.vue'
      ),
  },

  {
    meta: {
      title: 'Модерация',
    },
    path: '/moderation/:id',
    name: 'merchant-moderation',
    component: () => import(
      '../modules/Moderation/MerchantForm/MerchantForm.vue'
      ),
  },
  // ------------------------------------------------------------------------------------------------------


  {
    path: '/404',
    name: '404',
    component: NotFound,
  },

  {
    path: '*',
    redirect: '/404',
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes,
})

import store from '../store'


router.beforeEach((to, from, next) => {
  store.commit('hideSubmenu')
  next()
})

export default router
