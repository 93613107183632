
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

import LoginForm from '@/components/Auth/LoginForm.vue'

import NavBar from '@/components/Layout/NavBar.vue'
import AsideMenu from '@/components/Layout/AsideMenu.vue'

@Component({
  computed: {
    ...mapState([
      'token', 'actor',
    ]),
    ...mapGetters([
      'isAuthenticated',
    ]),
  },
  components: {
    LoginForm,
    AsideMenu,
    NavBar,
  },
})
export default class App extends Vue {
  public isAuthenticated!: boolean
  public actor!: any

  get menu() {

    return [

      'Пользователи',
      [
        {
          to: { name: 'brand-list' },
          label: 'Торговые бренды',
          icon: 'copyright',
        },
        {
          to: { name: 'merchant-list' },
          label: 'Организации',
          icon: 'store',
        },
        /*{
          to: { name: 'outlet-list' },
          label: 'Точки продаж',
          icon: 'cash-register',
        }, */
        {
          to: { name: 'customer-list' },
          label: 'Покупатели',
          icon: 'user',
        },
        {
          to: { name: 'suppliers-list' },
          label: 'Прозводители',
          icon: 'people-carry',
        },
        {
          to: { name: 'admin-list' },
          label: 'Администраторы',
          icon: 'user-cog',
        },
        {
          to: { name: 'admin-role-list' },
          label: 'Роли',
          icon: 'user-tag',
        },
        {
          to: { name: 'moderation' },
          label: 'Модерация',
          icon: 'cogs',
        },
      ],

      'Карты',
      [
        {
          to: { name: 'card-series-list' },
          label: 'Серии карт',
          icon: 'credit-card',
        },
      ],
      'Отчеты',
      [
        {
          to: { name: 'report-by-channel' },
          label: 'По каналам',
          icon: 'file-alt',
        },
        {
          to: { name: 'report-bonus-stats' },
          label: 'Бонусы',
          icon: 'file-alt',
        },
        {
          to: { name: 'report-by-cards' },
          label: 'По картам',
          icon: 'file-alt',
        },
        {
          to: { name: 'report-receipts' },
          label: 'Чеки',
          icon: 'file-alt',
        },
        {
          to: { name: 'report-customer' },
          label: 'Покупатели',
          icon: 'file-alt',
        },
      ],

      'Настройки',
      [
        /*{
          to: { name: 'city-list' },
          label: 'Города',
          icon: 'city',
        },
        {
          to: { name: 'merchant-category-list' },
          label: 'Категории организаций',
          icon: 'th-list',
        },
        {
          to: { name: 'product-category-list' },
          label: 'Категории продуктов',
          icon: 'th-list',
        },
        {
          to: { name: 'product-pattern-list' },
          label: 'Шаблоны продуктов',
          icon: 'th-list',
        },*/
        {
          to: { name: 'nomenclatures-list' },
          label: 'Товарная номенклатура',
          icon: 'th-list',
        },
        /*{
          to: { name: 'privacy-policy-edit' },
          label: 'Документы',
          icon: 'file-medical-alt',
        },
        {
          to: { name: 'onboarding-list' },
          label: 'Тур',
          icon: 'users',
        },
        {
          to: { name: '404' },
          label: 'Баннеры',
          icon: 'users',
          disabled: true
        },*/
        {
          to: { name: 'bonus-list' },
          label: 'Бонусы',
          icon: 'exclamation',
        },
        {
          to: { name: 'promotion-list' },
          label: 'Акции',
          icon: 'ad',
        },
        {
          to: { name: 'cashback-list' },
          label: 'Кэшбэк',
          icon: 'wallet',
        },
        {
          to: { name: 'cashback-transactions' },
          label: 'Транзакции',
          icon: 'exchange-alt',
        },
        /*{
          to: { name: '404' },
          label: 'Оплаты',
          icon: 'users',
          disabled: true
        },
        {
          to: { name: '404' },
          label: 'Шаблоны рассылок',
          icon: 'users',
          disabled: true
        },
        {
          to: { name: '404' },
          label: 'Общие настройки',
          icon: 'users',
          disabled: true
        },*/
      ],


      'Другое',
      [
        {
          to: { name: '404' },
          label: 'Монетизация',
          icon: 'users',
          disabled: true,
        },
        {
          to: { name: 'problem-list' },
          label: 'Обратная связь',
          icon: 'users',
        },
        {
          to: { name: '404' },
          label: 'Заявки',
          icon: 'users',
          disabled: true,
        },
        {
          to: { name: '404' },
          label: 'Отзывы',
          icon: 'comments',
          disabled: true,
        },
        {
          to: { name: 'messages' },
          label: 'Сообщения',
          icon: 'comment',
        },
        {
          to: { name: 'notifications' },
          label: 'Рассылки',
          icon: 'info',
        },
      ],
      'Настройки',
      [
        {
          to: { name: 'settings' },
          label: 'Меню настроек',
          icon: 'cogs',
        },
      ],
    ]
  }

}
