<template>
  <li :class="{'is-active':isDropdownActive}">
    <template v-if="componentIs === 'router-link'">
      <router-link
        :to="itemTo"
        @click.native.prevent="menuClick($event)"
        exact-active-class="is-active"
        :class="{'has-icon':!!item.icon, 'has-dropdown-icon':hasDropdown, disabled: item.disabled}"
      >
        <b-icon
          v-if="item.icon"
          :icon="item.icon"
          :class="{ 'has-update-mark' : item.updateMark }"
          custom-size="default"
        />
        <span v-if="item.label" :class="{'menu-item-label':!!item.icon}">{{ item.label }}</span>
        <div v-if="hasDropdown" class="dropdown-icon">
          <b-icon :icon="dropdownIcon" custom-size="default"/>
        </div>
      </router-link>
    </template>

    <template v-if="componentIs === 'a'">
      <a
        :href="itemHref"
        :target="itemHref ? '_blank' : false"
        @click.prevent="menuClick($event)"
        exact-active-class="is-active"
        :class="{'has-icon':!!item.icon, 'has-dropdown-icon':hasDropdown, disabled: item.disabled}"
      >
        <b-icon
          v-if="item.icon"
          :icon="item.icon"
          :class="{ 'has-update-mark' : item.updateMark }"
          custom-size="default"
        />
        <span v-if="item.label" :class="{'menu-item-label':!!item.icon}">{{ item.label }}</span>
        <div v-if="hasDropdown" class="dropdown-icon">
          <b-icon :icon="dropdownIcon" custom-size="default"/>
        </div>
      </a>
    </template>

    <aside-menu-list
      v-if="hasDropdown"
      :menu="item.menu"
      :isSubmenuList="true"
    />

    <portal to="submenu" v-if="item.submenu && activeSubmenu">
      <aside class="aside is-placed-left is-expanded is-secondary">
        <div class="aside-tools has-icon">
          <div class="aside-tools-label">
            <span class="icon">
              <i class="mdi mdi-view-list default"></i>
            </span>
            <span>{{ item.label }}</span>
          </div>
          <a class="aside-close" @click.prevent="closeSubmenu">
            <span class="icon"><i class="mdi mdi-close default"></i></span>
          </a>
        </div>
        <div class="menu-container">
          <div class="menu is-menu-main">
            <template v-for="(menuGroup, index) in item.props">
              <p class="menu-label" v-if="typeof menuGroup === 'string'" :key="index">{{ menuGroup }}</p>
              <aside-menu-list
                v-else
                :key="index"
                @menu-click="menuClick"
                :menu="menuGroup"
              />
            </template>

            <!-- <p class="menu-label">Something</p>
            <ul class="menu-list">
                <li class=""><a href="#void" class="has-icon"><span class="icon"><i class="mdi mdi-view-list default"></i></span><span class="menu-item-label">Sub-item One</span></a></li>
                <li class=""><a href="#void" class="has-icon"><span class="icon"><i class="mdi mdi-view-list default"></i></span><span class="menu-item-label">Sub-item Two</span></a></li>
            </ul>
            <p class="menu-label">Dropdown</p>
            <ul class="menu-list">
                <li class=""><a class="has-icon has-submenu-icon"><span class="icon"><i class="mdi mdi-view-list default"></i></span><span class="menu-item-label">Submenus</span><div class="submenu-icon"><span class="icon"><i class="mdi mdi-plus default"></i></span></div></a>
                    <ul class="">
                        <li class=""><a href="#void" class=""><span class="">Sub-item One</span></a></li>
                        <li class=""><a href="#void" class=""><span class="">Sub-item Two</span></a></li>
                    </ul>
                </li>
            </ul> -->
          </div>
        </div>
        <div class="menu is-menu-bottom">
          <ul class="menu-list"></ul>
        </div>
      </aside>
    </portal>
  </li>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AsideMenuItem',
  components: {
    AsideMenuList: () => import('@/components/Layout/AsideMenuList'),
  },
  data() {
    return {
      isDropdownActive: false,
    }
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    // submenuClick() {
    //   this.$emit('menu-click', this.item);

    //   console.log('submenuClick', this.item);

    //   this.closeSubmenu();

    //   if (this.hasDropdown) {
    //     this.isDropdownActive = (!this.isDropdownActive)
    //   }
    // },
    menuClick() {
      if (this.item.disabled) {
        return
      }

      this.$emit('menu-click', this.item)

      if (this.hasDropdown) {
        this.isDropdownActive = (!this.isDropdownActive)
      }

      if (this.item.submenu) {
        this.$store.commit('openSubmenu', this.item.submenu)
      }
    },
    closeSubmenu() {
      this.$store.commit('hideSubmenu')
    },
  },
  computed: {
    ...mapState([
      'activeSubmenu',
    ]),
    componentIs() {
      if (this.item.disabled) {
        return 'a'
      } else {
        return this.item.to ? 'router-link' : 'a'
      }
    },
    hasDropdown() {
      return !!this.item.menu
    },
    dropdownIcon() {
      return (this.isDropdownActive) ? 'minus' : 'plus'
    },
    itemTo() {
      if (this.item.disabled) {
        return null
      } else {
        return this.item.to ? this.item.to : null
      }
    },
    itemHref() {
      return this.item.href ? this.item.href : null
    },
  },
}
</script>

<style lang="scss" scoped>
aside.aside .menu-list li {
  a {
    display: flex;
    width: 100%;
    text-decoration: none;
  }

  .disabled {
    color: #666;

    &:hover {
      color: #666;
      background-color: transparent;
    }
  }

}
</style>
