











import { Component, Vue } from 'vue-property-decorator'

import TitleBar from '@/components/Elements/TitleBar.vue'

@Component({

  components: {
    TitleBar,
  },
})
export default class NotFound extends Vue {

  get titleStack() {
    return [
      'Страница не найдена',
    ]
  }

}

