



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

import AsideTools from '@/components/Layout/AsideTools.vue'
import AsideMenuList from '@/components/Layout/AsideMenuList.vue'

@Component({
  computed: {
    ...mapState([
      'isAsideVisible', 'isAsideExpanded', 'activeSubmenu',
    ]),
  },
  methods: {
    ...mapActions([
      'openSubmenu', 'hideSubmenu',
    ]),
  },
  components: { AsideTools, AsideMenuList },
})
export default class AsideMenu extends Vue {

  @Prop({ type: Array, default: [] })
  public menu!: any[]

  public VUE_APP_BRAND_NAME = process.env.VUE_APP_BRAND_NAME
  public VUE_APP_BRAND_SHORT_NAME = process.env.VUE_APP_BRAND_SHORT_NAME

  public menuClick(item: any) {
    //
  }

  public closeSubmenu() {
    this.$store.commit('hideSubmenu')
  }

}
