import '@/styles/main.scss'

// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'
// import { CaptureConsole } from '@sentry/integrations'

import Vue from 'vue'
import App from './App.vue'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck, faEdit, faUpload, faCopyright, faTrash, faPeopleCarry,
  faUserTag, faStore, faCashRegister, faUserCog, faUser, faCaretDown,
  faCaretUp, faPlus, faChevronUp, faChevronDown, faExclamationCircle,
  faAngleLeft, faAngleRight, faArrowUp, faArrowDown, faCreditCard, faWallet,
  faFileAlt, faFileMedicalAlt, faSignOutAlt, faThList, faExclamation,
  faExchangeAlt, faCity, faCogs, faComment, faComments, faAd, faRubleSign,
  faFrown, faArrowAltCircleLeft, faArrowAltCircleRight, faUsers, faCaretLeft,
  faCaretRight, faEllipsisV, faBan, faSearch, faImage, faMinus, faInfo,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCheck, faPeopleCarry, faUserTag, faUser, faEdit, faUpload,
  faCopyright, faTrash, faStore, faCashRegister, faUserCog, faCaretDown,
  faCaretUp, faPlus, faChevronUp, faChevronDown, faExclamationCircle,
  faAngleLeft, faAngleRight, faArrowUp, faArrowDown, faCreditCard, faWallet,
  faFileAlt, faFileMedicalAlt, faSignOutAlt, faThList, faExclamation,
  faExchangeAlt, faCity, faCogs, faComment, faComments, faAd, faRubleSign,
  faFrown, faArrowAltCircleLeft, faArrowAltCircleRight, faUsers, faCaretLeft,
  faCaretRight, faEllipsisV, faBan, faSearch, faImage, faMinus, faInfo,
)
dom.watch()
Vue.component('vue-fontawesome', FontAwesomeIcon)

// Sentry.init({
//   Vue,
//   dsn: "https://4d326136662347239b26c4705200ebbd@sentry.it-cow.ru/4",
//   integrations: [new Integrations.BrowserTracing(), new CaptureConsole({
//     levels: ['error']
//   })],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

import Buefy from 'buefy'

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
  defaultDayNames: [
    'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб',
  ],
  defaultMonthNames: [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
  ],
  defaultFirstDayOfWeek: 1,
})

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import {
  FormDatepickerPlugin, FormGroupPlugin, FormRadioPlugin, FormSelectPlugin,
  FormCheckboxPlugin, FormTextareaPlugin, FormSpinbuttonPlugin, BadgePlugin,
  FormPlugin, ModalPlugin, TabsPlugin, LayoutPlugin, CardPlugin,
} from '@/../node_modules/bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(FormDatepickerPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormSpinbuttonPlugin)
Vue.use(FormPlugin)
Vue.use(BadgePlugin)
Vue.use(ModalPlugin)
Vue.use(TabsPlugin)
Vue.use(LayoutPlugin)
Vue.use(CardPlugin)

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

Vue.use(PerfectScrollbar)

Vue.filter('shortString', function (value: string) {
  if (value) {
    return value.substr(0, 100)
  }
})

Vue.filter('moneyFormat', function (value: any) {
  if (value) {
    const a = value
    const b = a / 100
    const c = a % 100
    return b + '.' + c
  }
})

// @ts-ignore
import { Plugin } from 'vue-fragment'

Vue.use(Plugin)

import PortalVue from 'portal-vue'

Vue.use(PortalVue)

// @ts-ignore
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'

Vue.use(Donut)

import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

// @ts-ignore
import wysiwyg from 'vue-wysiwyg'

Vue.use(wysiwyg, {})

// import CleanName from './components/Controls/CleanName.vue';
// Vue.component('b-input-name', CleanName)


// import BankSelect from './components/Controls/BankSelect.vue';
// Vue.component('b-bank', BankSelect)

import './registerServiceWorker'
import router from './router'
import store from './store'

import { UbicPlugin } from './core'

Vue.use(UbicPlugin, { store })

import moment from 'moment'

Vue.filter('date', (value: string | Date, format: any) => {
  if (value) {
    return moment(value).format(format || 'DD.MM.YYYY')
  }
})

Vue.filter('formatDate', (value: string | Date) => {
  if (value) {
    return moment(value).format('DD.MM.YYYY')
  }
})

import Combobox from './components/Controls/Combobox.vue'

Vue.component('b-combobox', Combobox)

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

import AddressSelect from './components/Controls/AddressSelect.vue'

Vue.component('b-address', AddressSelect)

// @ts-ignore
import VueMask from './components/v-mask'

Vue.use(VueMask)

// import ProductVariantSelect from './components/Controls/ProductVariantSelect.vue';
// Vue.component('b-product-variant-select', ProductVariantSelect)

// import ContactsEditor from './components/Controls/ContactsEditor.vue';
// Vue.component('b-contacts-editor', ContactsEditor)

import numeral from 'numeral'
import { numeric } from 'vuelidate/lib/validators'
import { values } from 'lodash'
// import { values } from 'lodash';
// import numFormat from 'vue-filter-number-format';

const numFormat = (numeral: any) => {
  /**
   * apply a Numeral.js formatting string to an input value
   * @param {Number} val input value to be changed
   * @param {String} [format='0,0'] Numeral.js format string
   * @return {String} formatted output string
   */
  return (val: any, format = '0,0.00') => numeral(val || 0).format(format)
}
Vue.filter('numFormat', numFormat(numeral))

const sumFormat = (numeral: any) => {
  /**
   * apply a Numeral.js formatting string to an input value
   * @param {Number} val input value to be changed
   * @param {String} [format='0,0'] Numeral.js format string
   * @return {String} formatted output string
   */
  return (val: any, format = '0,0.00') => {
    let _val: any = numeral(val).format(format)
    if (val > 0) {
      _val = '<span class="discount-value">-' + val + '</span>'
    }
    else {
      _val = '<span class="paid-value">+ ' + val + '</span>'
    }
    return _val
  }
}
Vue.filter('sumFormat', sumFormat(numeral))

Vue.filter('channelName', function (channel: string) {
  const _names: any = {
    client_input: 'ручной ввод',
    email: 'почта',
    qr: 'QR',
  }
  return _names[channel]
})
Vue.filter('typeName', function (type: string) {
  const _names: any = {
    percent: 'Процент',
    fixed: 'Сумма',
    producer: 'Производитель',
    merchant: 'Магазин',
    producer_merchant: 'Производитель-магазин',
  }
  return _names[type]
})

Vue.filter('prettyBytes', function (num: number) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number')
  }

  // let exponent;
  // let unit;
  const neg = num < 0
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  if (neg) {
    num = -num
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B'
  }

  const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
  const rnum = (num / Math.pow(1000, exponent)).toFixed(2) // * 1;
  const unit = units[exponent]

  return (neg ? '-' : '') + rnum + ' ' + unit
})

Vue.filter('prettyTimeLong', function (num: number) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number')
  }

  // let exponent;
  // let unit;
  const neg = num < 0
  const units = ['с', 'м', 'ч']

  if (neg) {
    num = -num
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' с'
  }

  const exponent = Math.min(Math.floor(Math.log(num) / Math.log(60)), units.length - 1)
  const rnum = (num / Math.pow(60, exponent)).toFixed(1) // * 1;
  const unit = units[exponent]

  return (neg ? '-' : '') + rnum + ' ' + unit
})

Vue.filter('pad', function (num: number, size: number) {
  return new Array(size).join('0').slice((size || 2) * -1) + num
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
