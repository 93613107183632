import _Vue from 'vue'
import Vuex from 'vuex'

import Axios from 'axios'

// import { ServerQueryService } from './http/server_query';
// import { QueryBuilder } from './uql';

import {
  Plugins,
  // PushNotification,
  // PushNotificationToken,
  // PushNotificationActionPerformed,
} from '@capacitor/core'
// import { AbstractQueryAction } from './http';
// import { ServerResult } from './http/server_result';

const { Storage, PushNotifications } = Plugins

// this.storage.*

// this.$auth.login
// this.$auth.logout
// this.$auth.isAuthenticated

// this.$http.*

// this.$q('EMPLOYEE_LIST').select ....
// this.$db


// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function UbicPlugin<AxiosPlugOptions>(Vue: typeof _Vue, options?: UbicPluginOptions): void {

  // @ts-ignore
  if (UbicPlugin.installed) {
    return
  }

  // @ts-ignore
  UbicPlugin.installed = true

  if (!Axios) {
    // tslint:disable-next-line:no-console
    console.error('You have to install axios')
    return
  }


  // SETUP HTTP
  Axios.defaults.baseURL = process.env.VUE_APP_SERVER_ADDRESS
  Axios.interceptors.response.use((response) => {
    // Do something with response data
    return response
  }, (error) => {
    // Do something with response error
    if (error.response.status === 401) {
      (options as any).store.dispatch('logout')
    }

    return Promise.reject(error)
  })

  Axios.interceptors.request.use((config: any) => {
    config.headers['Content-Type'] = 'application/json'
    // config.headers['X-Auth-Target'] = 'user';
    config.headers.Authorization = 'Bearer ' + (options as any).store.state.token
    return config
  })

  Vue.prototype.$http = Axios


  // SETUP API
  // Vue.prototype.$q = (name: string): QueryBuilder => {
  //   return new QueryBuilder(name);
  // };

  // const api: ServerQueryService = new ServerQueryService(Axios);
  // Vue.prototype.$db = (operations: AbstractQueryAction[], flags?: any): Promise<ServerResult> => {
  //   const requestOptions = {
  //     // headers: {
  //     //   // headers = headers.append('Content-Type', 'application/json');
  //     //   // headers = headers.append('Authorization', this.authenticationService.currentUser.token);
  //     //   'Content-Type': 'application/json',
  //     //   'Authorization': (options as any).store.state.core.token
  //     // },
  //   };

  //   // console.log('requestOptions', requestOptions, (options as any).store.state.core.token)
  //   return api.instantQuery(operations, requestOptions);
  // };

  // Storage.get({ key: 'DEALER_CODE' }).then((data) => {
  //   if (data) {
  //     (options as any).store.dispatch('setDealer', JSON.parse((data.value as string)).code);
  //   } else {
  //     (options as any).store.dispatch('setDealer');
  //   }
  // });

  // (options as any).store.subscribe((mutation: any, state: any) => {
  //   switch (mutation.type) {
  //     case 'SET_DEALER_DATA': {
  //       Storage.set({ key: 'DEALER_CODE', value: JSON.stringify(mutation.payload) });
  //       break;
  //     }
  //   }
  // });

  Storage.get({ key: 'IDENTITY' }).then((data) => {
    if (data) {
      (options as any).store.dispatch('prepare', JSON.parse((data.value as string)))
    }
    else {
      (options as any).store.dispatch('prepare')
    }
  });

  (options as any).store.subscribe((mutation: any, state: any) => {
    switch (mutation.type) {
      case 'SET_IDENTITY': {
        Storage.set({ key: 'IDENTITY', value: JSON.stringify(mutation.payload) })
        break
      }
    }
  })

  Object.defineProperties(Vue.prototype, {
    $http: {
      get() {
        return Axios
      },
    },
  })


  // PushNotifications.register();
  // (options as any).store.dispatch('pushRegistration');

  // console.log('Push registration start');

  // PushNotifications.addListener('registration',(token: PushNotificationToken) => {
  //   console.log('Push registration success, token: ' + token.value);

  //   (options as any).store.dispatch('pushRegistrationSuccess', token.value);
  // });

  // // Some issue with our setup and push will not work
  // PushNotifications.addListener('registrationError', (error: any) => {
  //   // alert('Error on registration: ' + JSON.stringify(error));
  //   console.log('Push / Error on registration: ' + JSON.stringify(error));
  //   (options as any).store.dispatch('pushRegistrationError', error);
  // });

  // // Show us the notification payload if the app is open on our device
  // PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
  //   // alert('Push received: ' + JSON.stringify(notification));
  //   console.log('Push received: ' + JSON.stringify(notification));
  //   (options as any).store.dispatch('pushReceived', notification);
  // });

  // // Method called when tapping on a notification
  // PushNotifications.addListener('pushNotificationActionPerformed', (notification: PushNotificationActionPerformed) => {
  //   // alert('Push action performed: ' + JSON.stringify(notification));
  //   // console.log('Push action performed: ' + JSON.stringify(notification));
  //   (options as any).store.dispatch('pushActionPerformed', notification);
  // });

}

export class UbicPluginOptions {
  public store: any
}
