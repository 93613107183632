










































import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class AuthForm extends Vue {

  public form = {
    email: '',
    password: '',
  }

  public validationError: string | null = null

  public messages = {
    admin_not_found: 'Не верно заполен логин или пароль',
  }

  public mounted() {
    document.documentElement.classList.remove('has-aside-left')
    document.documentElement.classList.remove('has-navbar-fixed-top')
  }

  public destroyed() {
    document.documentElement.classList.add('has-aside-left')
    document.documentElement.classList.add('has-navbar-fixed-top')
  }

  public changeEmail($event: any) {
    this.form.email = $event.target.value
  }

  public changePass($event: any) {
    this.form.password = $event.target.value
  }

  public email(form: any) {
    this.$store.dispatch('authorize', form)
      .then(() => {
        this.$router.push({ name: 'brand-list' })
      })
      .catch((error: any) => {
        console.log(`catch: ${error}`)

        this.validationError = error.code
      })
  }
}

