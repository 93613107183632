













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { AxiosResponse } from 'axios'

import debounce from 'lodash/debounce'

@Component
export default class AddressSelect extends Vue {
  @Prop() public value!: any

  @Prop({ type: Boolean, default: false }) public disabled!: boolean

  @Prop({ type: String }) public placeholder!: string

  public isSuggestionsFetching = false
  public suggestions: any[] = []

  public getAsyncDataDebounce = debounce(this.getAsyncData, 500)

  public getAsyncData(name: any) {
    if (!name.length) {
      this.suggestions = []
      return
    }

    if (name.length < 3) {
      return
    }

    this.isSuggestionsFetching = true;

    (this as any).$http.get(`/admins/rest/v1/dadata/suggest`, {
      params: {
        q: name,
      },
    })
      .then(({ data }: any) => {
        this.$set(this, 'suggestions', data.result)
      })
      .catch((error: any) => {
        this.suggestions = []
        throw error
      })
      .finally(() => {
        this.isSuggestionsFetching = false
      })
  }

  public initFromSuggestion({ value, data, unrestricted_value }: any) {
    this.$emit('input', value)
    this.$emit('input-point', {
      lat: data.geo_lat,
      lng: data.geo_lon,
    })
    this.$emit('input-city', data.city)
    this.$emit('input-fias', data.city_fias_id)
    this.$emit('input-kladr', data.city_kladr_id)
  }

// geo_lat: "55.648586"
// geo_lon: "37.326653"
}
